import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/assm_eval.css'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import validator from 'validator'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'

import { baseline_assm_steps } from '../../variables/baseline_assm.js'
import { followup_assm_steps, followup_assm_steps_3m } from '../../variables/followup_assm.js'
import { ecfu_scale1, csq8_questions1, ecfu_scale2  } from '../../variables/baseline_exercise_follow_up.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Cookies from 'js-cookie'

class ExerciseClassFollowUp extends Component {
  constructor() {
    super()
    this.state = {
        status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        compTxt: '',
        timepoint: 0,
        survey_date: '',
        q1: 0,
        q2_1: 0,
        q2_2: 0,
        q2_3: 0,
        q2_4: 0,
        q2_5: 0,
        q2_6: 0,
        q2_7: 0,
        q2_8: 0,
        q2_9: 0,
        q2_10: 0,
        q2_11: 0,
        q2_12: 0,
        q2_12_specify: '',
        q3: 0,
    }
  }

  componentDidMount() {
    this.getProcess()
  }

  getProcess = () => {
    var timepoint = 0
    if(this.props.match.params.timepoint > 0) {
      timepoint = this.props.match.params.timepoint
    }
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    //console.log(sep_uri[2])
    if(sep_uri[2] == 'followup') {
      axios({
        method: 'post',
        url: connectNode + 'participants/followup/get_followup_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          assm: 'ecfu',
          tp_type: this.props.match.params.tp_type,
          timepoint: timepoint,
        }
      })
      .then((result) => {
        const get_date = result['data'][0]
        if(result['data'][1].id > 0) {
          const get_val = result['data'][1].assm_val
          this.setState({
            completed_status: true,
            timepoint: get_val.timepoint,
            survey_date: get_date.substr(5, 2)+'/'+get_date.substr(8, 2)+'/'+get_date.substr(0, 4),
            q1: get_val.q1,
            q2_1: get_val.q2_1,
            q2_2: get_val.q2_2,
            q2_3: get_val.q2_3,
            q2_4: get_val.q2_4,
            q2_5: get_val.q2_5,
            q2_6: get_val.q2_6,
            q2_7: get_val.q2_7,
            q2_8: get_val.q2_8,
            q2_9: get_val.q2_9,
            q2_10: get_val.q2_10,
            q2_11: get_val.q2_11,
            q2_12: get_val.q2_12,
            q2_12_specify: get_val.q2_12_specify,
            q3: get_val.q3,
          })
        } else {
          this.setState({
            completed_status: false,
            survey_date: get_date.substr(5, 2)+'/'+get_date.substr(8, 2)+'/'+get_date.substr(0, 4),
          })
        }

        if(result['data'].q1) {
          const get_val = result['data'].created
          this.setState({
            //completed_status: true,
            timepoint: timepoint,
            survey_date: get_val.substr(5, 2)+'/'+get_val.substr(8, 2)+'/'+get_val.substr(0, 4),
            q1: get_val.q1,
            q2_1: get_val.q2_1,
            q2_2: get_val.q2_2,
            q2_3: get_val.q2_3,
            q2_4: get_val.q2_4,
            q2_5: get_val.q2_5,
            q2_6: get_val.q2_6,
            q2_7: get_val.q2_7,
            q2_8: get_val.q2_8,
            q2_9: get_val.q2_9,
            q2_10: get_val.q2_10,
            q2_11: get_val.q2_11,
            q2_12: get_val.q2_12,
            q2_12_specify: get_val.q2_12_specify,
            q3: get_val.q3,
          })
        } else if(result['data'].created) {
          const get_val = result['data'].created
          this.setState({
            //completed_status: true,
            timepoint: timepoint,
            survey_date: get_val.substr(5, 2)+'/'+get_val.substr(8, 2)+'/'+get_val.substr(0, 4),
          })
        }
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
  }

  handleChangeQ2 = (e, name) => {
    if(e.target.checked) {
      this.setState({
        [name]: e.target.value,
      })
    } else {
      this.setState({
        [name]: 0,
      })
    }
  }

  validateAllField = () => {
    var chk = true
    var txt_warning = ''
    var arr = ['q1']
    arr.map(each => {
      if(this.state[each] == 0) {
        chk = false
        const lang = Cookies.get('lang_')
        if(lang=='es') {
          txt_warning = 'Por favor entre una respuesta para todas las preguntas'
        } else {
          txt_warning = 'Please enter a response to all questions.'
        }
        return ''
      }
    })

    return txt_warning
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var tp_type = this.props.match.params.tp_type
    var timepoint = this.props.match.params.timepoint
    if(this.state.completed_status) {
      if(tp_type == 'm' && timepoint == 3) {
        this.props.onLoadLocation('/member/followup/exit_interview/'+tp_type+'/'+timepoint)
        this.props.history.push('/member/followup/exit_interview/'+tp_type+'/'+timepoint)
      } else {
        this.props.onLoadLocation('/member/followup/completed/'+tp_type+'/'+timepoint)
        this.props.history.push('/member/followup/completed/'+tp_type+'/'+timepoint)
      }
    } else {
      var get_validate_msg = this.validateAllField()
      //console.log(get_validate_msg)
      if(get_validate_msg == '') {
        var pid = this.props.authReducer.uid
        var get_uri = this.props.location.pathname
        var sep_uri = get_uri.split("/")
        var get_steps = []
        var current_step = 0
        if(sep_uri[2] == 'followup') {
          axios({
            method: 'post',
            url: connectNode + 'participants/followup/add_ecfu',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              pid: pid,
              tp_type: tp_type,
              timepoint: timepoint,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] == pid) {
              window.scrollTo(0, 0)
              if(tp_type == 'm' && timepoint == 3) {
                this.props.onLoadLocation('/member/followup/exit_interview/'+tp_type+'/'+timepoint, 'Thank you for submitting your Exercise Class Follow-Up!')
                this.props.history.push('/member/followup/exit_interview/'+tp_type+'/'+timepoint)
              } else {
                this.props.onLoadLocation('/member/followup/completed/'+tp_type+'/'+timepoint, 'Thank you for submitting your Exercise Class Follow-Up!')
                this.props.history.push('/member/followup/completed/'+tp_type+'/'+timepoint)
              }
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
        }
      } else {
        this.setState({
          message: get_validate_msg,
        })
        window.scrollTo(0, 0)
      }
    }
  }

  genTypesChecked = () => {
    var buff = ''
    for(var i=1; i<=12; i++) {
      if(this.state['q2_'+i] != 0) {
        if(buff.length == 0) {
          buff += '('
        } else if(buff.length > 0) {
          buff += ', '
        }
        buff += ecfu_scale2[i-1].label
      }
    }
    if(buff.length > 0) {
      buff += ')'
    }
    return buff
  }

  handleChange6 = (e, name, limit) => {
    if(Number(e.target.value) <= limit) {
      this.setState({
        [name]:e.target.value
      })
    }
  }

  render() {
    const lang = Cookies.get('lang_')
    var get_uri = this.props.location.pathname
    var sep_uri = get_uri.split("/")
    var get_steps = []
    var current_step = 0
    if(sep_uri[2] == 'followup') {
      get_steps = followup_assm_steps
      current_step = 8
      if(sep_uri[4] == 'm') {
        get_steps = followup_assm_steps_3m
      }
    }

    return (
    <div className="participant1 ecfu">
        <div className="headerPage3_1">
        {sep_uri[2] == 'followup' && 'Assessments: ' + sep_uri[5] }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'w' && ' weeks' }
        {sep_uri[2] == 'followup' && sep_uri[4] == 'm' && ' months' }
        </div>
        <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={current_step}>
            {get_steps.map(each => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={'st'+each.value} {...stepProps}>
                  <StepLabel {...labelProps}>{each.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className="small-12 columns box_assm1 more_padding1">
          { (this.state.message !== '' || this.state.compTxt !== '') &&
          <div className="row margin-bottom1">
            <div className={(this.state.message !== '' && 'small-12 columns warning_msg6')
            || (this.state.compTxt !== '' && 'small-12 columns warning_msg7')} >
              <div className="float_left1">
              {this.state.message !== '' && <WarningIcon />}
              {this.state.compTxt !== '' && <CheckCircleOutlineIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message || this.state.compTxt }</div>
            </div>
          </div>}
        </div>
        <div className="small-12 columns box_border3">
          <div className="row">
            <div className="small-12 columns headerPage3_2">
              {(lang=='en' && 'Exercise Class Follow-Up') || (lang=='es' && 'Exercise Class Follow-Up')}
            </div>
            <div className="small-12 columns">
              {(lang=='en' && 'Please complete the survey below. Thank you!') || (lang=='es' && 'Please complete the survey below. Thank you!')}
            </div>
            <div className="small-12 columns sep_line1">&nbsp;</div>
            <div className="small-12 columns">
              <div className="row">
                <div className="small-12 medium-8 columns">
                Since beginning the HAIL program on {this.state.survey_date},
                have you participated in any other exercise classes?
                </div>
                {ecfu_scale1.map(each =>
                <div className="small-12 medium-2 columns">
                  <div className="small-12 columns top_space2" key={'qs1'+each.value}>
                    <input type="radio" name="q1" value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state.q1 == each.value && 'checked'}
                    onChange={(e) => this.handleChange2(e, 'q1')}/>
                    <span>{each.label}</span>
                  </div>
                </div>)}
                {this.state.q1 == 1 && <>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-6 columns">Which type of class did you participate in?</div>
                <div className="small-12 medium-6 columns">
                {ecfu_scale2.map(each =>
                <div className="small-12 columns">
                  <div className="small-12 columns top_space2" key={'qs1'+each.value}>
                    <input type="checkbox" name={"q2_"+each.value} value={each.value}
                    disabled={this.state.completed_status && 'disabled'} className="rad1"
                    checked={this.state["q2_"+each.value] == each.value && 'checked'}
                    onChange={(e) => this.handleChangeQ2(e, "q2_"+each.value)}/>
                    <span>{each.label}</span>
                  </div>
                  {each.value == 12 && this.state.q2_12 == 12 &&
                    <TextField onChange={this.handleChange} variant="outlined"
                    disabled={this.state.completed_status && 'disabled'} name="comments"
                    type="text" value={this.state.q2_12_specify} className="w_80per top_space1"
                    InputProps={{ inputProps: { name: "q2_12_specify", maxLength: 250, placeholder: 'Please specify' }}} />}
                </div>)}
                </div>
                <div className="small-12 columns sep_line1">&nbsp;</div>
                <div className="small-12 medium-8 columns">
                  Approximately how many times have you done these classes {this.genTypesChecked()} since the HAIL program started on {this.state.survey_date}?
                </div>
                <div className="small-12 medium-4 columns">
                <TextField label="" type="text" onChange={(e) => this.handleChange6(e, 'q3', 99)}
                  variant="outlined" InputProps={{ maxLength: 2 }} value={this.state.q3}
                  disabled={this.state.completed_status && 'disabled'} />
                </div></>}
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns text_align_right1 top_space1">
          <Button variant="contained" color="primary"
             onClick={this.handleSubmit} type="submit">
             {!this.state.completed_status && 'Submit'}
             {this.state.completed_status && 'Next'}
          </Button>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ExerciseClassFollowUp)
