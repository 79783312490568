import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'

//import imageSignature from '../../../../impact_bd_server/uploads/signatures/patient_contract/signature_57_8.png'

class GetPDF extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pdf: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = 0
    if(this.props.authReducer.role == 5) {
      pid = this.props.authReducer.uid
    } else if(this.props.authReducer.role == 2) {
      pid = this.props.match.params.pid
    }

    var type_id = 0
    if(this.props.match.params.type_id > 0) {
      type_id = this.props.match.params.type_id
    }
    if(this.props.type_id > 0) {
      type_id = this.props.type_id
    }

    var path_get_file = ''
    if(type_id == 1) {
      path_get_file = 'participants/contract/get_contract_commitment_pdf_no_gen'
    } else if(type_id == 2) {
      path_get_file = 'participants/contract/get_contract_commitment_pdf_no_gen'
    } else if(type_id == 3) {
      path_get_file = 'participants/contract/get_contract_commitment_pdf_no_gen'
    } else if(this.props.match.params.fid > 0) {
      path_get_file = 'admin/participant/get_uploaded_file'
    }
    //console.log(this.props.match.params.fid)

    // get image //
    axios({
      method: 'post',
      url: connectNode + path_get_file,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
        type: type_id,
        fid: this.props.match.params.fid,
        //contract_id: this.props.match.params.contract_id,
      },
    })
    .then((result) => {
      this.setState({
          pdf: result['data']
      })
    })
  }

  render() {
    //console.log('---'+this.state.pdf.data)
    return (
      <div>
      {this.state.pdf.data === undefined &&
        <div className="loading1">Loading...</div>
      }
      {this.state.pdf.data === -1 &&
        <div className="loading1">Incomplete</div>
      }
      {this.state.pdf.data !== undefined &&
      <object data={`data:application/pdf;base64,${this.state.pdf.data}`}
            type="application/pdf" className="pdf_size_1" />
      }</div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GetPDF)
