export const phq9_questions1 = [
  {
    label: 'Little interest or pleasure in doing things',
    value: 1,
  }, {
    label: 'Felling down, depressed or hopeless',
    value: 2,
  }, {
    label: 'Trouble falling asleep, staying asleep, or sleeping too much',
    value: 3,
  }, {
    label: 'Feeling tired or having little energy',
    value: 4,
  }, {
    label: 'Poor appetite or overeating',
    value: 5,
  }, {
    label: "Feeling bad about yourself - or that you're a failure or have let yourself or your family down",
    value: 6,
  }, {
    label: 'Trouble concentrating on things, such as reading the newspaper or watching television',
    value: 7,
  }, {
    label: 'Moving or speaking so slowly that other people could have noticed. Or, the opposite being so fidgety or restless that you have been moving around a lot more than usual',
    value: 8,
  }, {
    label: 'Thoughts that you would be better off dead or of hurting yourself in some way',
    value: 9,
  },
]

export const phq9_scale1 = [
  {
    label: 'Not At all',
    value: 0,
  }, {
    label: 'Several Days',
    value: 1,
  }, {
    label: 'More Than Half the Days',
    value: 2,
  }, {
    label: 'Nearly Every Day',
    value: 3,
  },
]

export const phq9_questions2 = [
  {
    label: 'If you checked off any problems, how difficult have those problems made it for you to do your work, take care of things at home, or get along with other people?',
    value: 10,
  },
]

export const phq9_scale2 = [
  {
    label: 'Not difficult at all',
    value: 1,
  }, {
    label: 'Somewhat difficult',
    value: 2,
  }, {
    label: 'Very difficult',
    value: 3,
  },  {
    label: 'Extremely difficult',
    value: 4,
  },
]

export const phq9_questions1_es = [
  {
    label: 'Poco interés o placer en hacer cosas',
    value: 1,
  }, {
    label: 'Se ha sentido decaído(a), deprimido(a) o sin esperanzas',
    value: 2,
  }, {
    label: 'Ha tenido dificultad para quedarse o permanecer dormido(a), o ha dormido demasiado',
    value: 3,
  }, {
    label: 'Se ha sentido cansado(a) o con poca energía',
    value: 4,
  }, {
    label: 'Sin apetito o ha comido en exceso',
    value: 5,
  }, {
    label: "Se ha sentido mal con usted mismo(a) – o que es un fracaso o que ha quedado mal con usted mismo(a) o con su familia",
    value: 6,
  }, {
    label: 'Ha tenido dificultad para concentrarse en ciertas actividades, tales como leer el periódico o ver la televisión',
    value: 7,
  }, {
    label: '¿Se ha movido o hablado tan lento que otras personas podrían haberlo notado? o lo contrario – muy inquieto(a) o agitado(a) que ha estado moviéndose mucho más de lo normal',
    value: 8,
  }, {
    label: 'Pensamientos de que estaría mejor muerto(a) o de lastimarse de alguna manera',
    value: 9,
  },
]

export const phq9_scale1_es = [
  {
    label: 'Ningún día',
    value: 0,
  }, {
    label: 'Varios días',
    value: 1,
  }, {
    label: 'Más de la mitad de los días',
    value: 2,
  }, {
    label: 'Casi todos los días',
    value: 3,
  },
]

export const phq9_questions2_es = [
  {
    label: 'Si marcó cualquiera de los problemas, ¿qué tanta dificultad le han dado estos problemas para hacer su trabajo, encargarse de las tareas del hogar, o llevarse bien con otras personas?',
    value: 10,
  },
]

export const phq9_scale2_es = [
  {
    label: 'No ha sido difícil',
    value: 1,
  }, {
    label: 'Un poco difícil',
    value: 2,
  }, {
    label: 'Muy difícil',
    value: 3,
  },  {
    label: 'Extremadamente difícil',
    value: 4,
  },
]
